/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createViewLog = /* GraphQL */ `
  mutation CreateViewLog(
    $input: CreateViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    createViewLog(input: $input, condition: $condition) {
      id
      path
      email
      duration
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateViewLog = /* GraphQL */ `
  mutation UpdateViewLog(
    $input: UpdateViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    updateViewLog(input: $input, condition: $condition) {
      id
      path
      email
      duration
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteViewLog = /* GraphQL */ `
  mutation DeleteViewLog(
    $input: DeleteViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    deleteViewLog(input: $input, condition: $condition) {
      id
      path
      email
      duration
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserDetail = /* GraphQL */ `
  mutation CreateUserDetail(
    $input: CreateUserDetailInput!
    $condition: ModelUserDetailConditionInput
  ) {
    createUserDetail(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserDetail = /* GraphQL */ `
  mutation UpdateUserDetail(
    $input: UpdateUserDetailInput!
    $condition: ModelUserDetailConditionInput
  ) {
    updateUserDetail(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserDetail = /* GraphQL */ `
  mutation DeleteUserDetail(
    $input: DeleteUserDetailInput!
    $condition: ModelUserDetailConditionInput
  ) {
    deleteUserDetail(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

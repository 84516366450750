import { Link } from "react-router-dom";

import Bg from "../assets/images/awards-player-middle.png"
import HeaderImg from "../assets/images/awards-player-header.png";
import FooterImg from "../assets/images/awards-player-bottom.png";
import { useAuthContext } from "../contexts/Auth";
import TechSupport from "./TechSupport";

const PortalLayout = ({ children }) => {
  const { logout, user } = useAuthContext();

  return (
    <main style={{ width: "100%", backgroundColor: "lightgray" }}>
      {/* <Link to="/home"> */}
      <img
        className="img-fluid"
        src={HeaderImg}
        style={{ maxHeight: 200, objectFit: 'cover', width: "100%" }}
      />
      {/* </Link> */}

      {/* <button className="btn btn-logout" style={{ color: "white" }} onClick={logout}>
        Log out
      </button> */}

      <nav className="navbar navbar-expand-md navbar-dark py-0">    
        <div className="container-fluid px-0">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navmenu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navmenu">
            <ul className="navbar-nav nav-fill w-100">
              <li className="nav-item nav-bg-color2">
                <Link
                  className="flex-sm-fill text-center nav-link fs-4 border-end border-3"
                  to="/home"
                >
                  Meeting Pulse
                </Link>
              </li>
              <li className="nav-item nav-bg-color2">
                <Link
                  className="flex-sm-fill text-center nav-link fs-4 border-end border-3"
                  to="/home-alt"
                >
                  Pigeonhole
                </Link>
              </li>
              <li className="nav-item nav-bg-color2">
                <Link
                  className="flex-sm-fill text-center nav-link fs-4 border-3 border-end"
                  to="/home-backup"
                >
                  Slido
                </Link>
              </li>
              <li className="nav-item nav-bg-color2">
                <Link
                  className="flex-sm-fill text-center nav-link fs-4 border-3 border-end"
                  to="/slido"
                >
                  Slido v2
                </Link>
              </li>
              <li className="nav-item nav-bg-color2">
                <Link
                  className="flex-sm-fill text-center nav-link fs-4 border-3 border-end"
                  to="/slido3"
                >
                  Slido v3
                </Link>
              </li>
              <li className="nav-item nav-bg-color2">
                <Link
                  className="flex-sm-fill text-center nav-link fs-4 border-3"
                  to="/newpulse"
                >
                  New Pulse
                </Link>
              </li>
            </ul>
          </div>
        
        </div>
      </nav>


      {children}
            
      {/* {user.group === 'B' && ( */}
      <nav className="nav flex-column bg-white">
        <TechSupport />
      </nav>
      {/* )} */}
      <img
        className="img-fluid"
        src={FooterImg}
        style={{ maxHeight: 150, objectFit: 'cover' }}
      />
    </main>
  );
};

export default PortalLayout;

import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import loadable from "@loadable/component";

import AuthLayout from "./components/AuthLayout";
import PortalLayout from "./components/PortalLayout";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import { useAuthContext } from "./contexts/Auth";
import { API, graphqlOperation } from "aws-amplify";
import { createViewLog, updateViewLog } from "./graphql/mutations";

const Home = loadable(() => import("./pages/home"));
const NewPulse = loadable(() => import("./pages/newpulse"));
const Slido = loadable(() => import("./pages/slido"));
const Slido3 = loadable(() => import("./pages/slido3"));
const HomeAlt = loadable(() => import("./pages/home-alt"));
const Homebackup = loadable(() => import("./pages/home-backup"));
const Login = loadable(() => import("./pages/login"));
const Loading = loadable(() => import("./pages/loading"));

function AuthRoutes() {
  return (
    <AuthLayout>
      <Switch>
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
      </Switch>
    </AuthLayout>
  );
}

function PortalRoutes() {
  return (
    <PortalLayout>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/newpulse">
          <NewPulse />
        </Route>
        <Route exact path="/slido">
          <Slido />
        </Route>
        <Route exact path="/slido3">
          <Slido3 />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/home-alt">
          <HomeAlt />
        </Route>
        <Route exact path="/home-backup">
          <Homebackup />
        </Route>
      </Switch>
    </PortalLayout>
  );
}

let startTime;
let viewLog;

export default function Routes() {
  const location = useLocation();
  const { isAuthLoading, user } = useAuthContext();

  useEffect(() => {
    startTime = new Date();

    const interval = setInterval(function () {
      if (viewLog) {
        API.graphql(graphqlOperation(updateViewLog, { input: { id: viewLog.id, duration: new Date() - startTime, _version: viewLog._version } }))
          .then(({ data }) => {
            viewLog = data.updateViewLog
          })
      }
    }, 15 * 1000);

    return () => clearInterval(interval)
  }, []);

  useEffect(() => {
    if (viewLog) {
      API.graphql(graphqlOperation(updateViewLog, { input: { id: viewLog.id, duration: new Date() - startTime, _version: viewLog._version } }))
        .then(({ data }) => {
          viewLog = data.updateViewLog
        })
    }
    if (user?.email) {
      API.graphql(graphqlOperation(createViewLog, {
        input: {
          path: location.pathname,
          email: user?.email,
          duration: 0,
        }
      }))
        .then(({ data }) => {
          viewLog = data.createViewLog
        })
    }
    startTime = new Date();
  }, [location.pathname]);

  useEffect(() => {
    if (viewLog) {
      API.graphql(graphqlOperation(updateViewLog, { input: { id: viewLog.id, email: user?.email, _version: viewLog._version } }))
        .then(({ data }) => {
          viewLog = data.updateViewLog
        })
    }
  }, [user]);

  if (isAuthLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route
        path={[
          "/login",
        ]}
        component={AuthRoutes}
      />
      <Route
        path={[
          "/home",
          "/newpulse",
          "/slido",
          "/slido3",
          "/home-alt",
          "/home-backup",
        ]}
        component={PortalRoutes}
      />
      <Redirect to="/home" />
    </Switch>
  );
}

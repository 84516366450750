import HeaderImg from "../assets/images/awards-player-header.png";
import BackImg from "../assets/images/awards-player-middle.png";
import FooterImg from "../assets/images/awards-player-bottom.png";

const AuthLayout = ({ children }) => {
  return (
    <main className="d-flex flex-direction-column" style={{ height: '100vh', background: '#14190d', overflow: "hidden" }}>
      <img className="img-fluid" src={HeaderImg} alt="backgorund" style={{ maxHeight: 200, objectFit: 'cover' }} />
      <img className="img-fluid" src={BackImg} alt="backgorund" style={{ flex: 1, objectFit: 'cover' }} />
      <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        {children}
      </div>
      <img className="img-fluid" src={FooterImg} alt="backgorund" style={{ maxHeight: 200, objectFit: 'cover' }} />
    </main>
  );
};

export default AuthLayout;
